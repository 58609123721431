import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { Color } from "@tiptap/extension-color";
import Highlight from "@tiptap/extension-highlight";
import Image from "@tiptap/extension-image";
import Link from "@tiptap/extension-link";
import ListItem from "@tiptap/extension-list-item";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import TextAlign from "@tiptap/extension-text-align";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import { EditorProvider } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MenuBar from "./menu";

const extensions = [
  Image,
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle.configure({ types: [ListItem.name] }),
  TextAlign.configure({ types: ["heading", "paragraph", "image"] }),
  Highlight.configure({ multicolor: true }),
  Underline,
  Link.configure({ openOnClick: false, linkOnPaste: true }),
  Table.configure({ resizable: true }),
  TableRow,
  TableHeader,
  TableCell,
  StarterKit.configure({ bulletList: { keepMarks: true, keepAttributes: false }, orderedList: { keepMarks: true, keepAttributes: false } }),
];

export default function Editor(props) {
  const { name, title, description, value, modules, formats, forecolors, backcolors, error, errorMessage, isRequired, onBlur, disabled, onChange } = props;

  // PARAMS
  const location = useLocation();

  // STATE
  const [started, setStarted] = useState(false);
  const [uninitialized, setUninitialized] = useState(true);

  // VARS
  const styles = {
    title: "block text-sm font-medium mb-1",
    description: "block text-xs font-light text-slate-400 -mt-1.5 mb-1",
  };
  if (error) styles.title += " text-red-600";
  else styles.title += " text-slate-700";

  const onUpdate = ({ editor }) => {
    if (!started) setStarted(true);
    onChange(editor.getHTML());
  };

  useEffect(() => {
    if (value && uninitialized && !started) {
      setStarted(true);
      setUninitialized(false);
      setTimeout(() => setUninitialized(true), 300);
    }
  }, [value, uninitialized, started]);

  useEffect(() => {
    setStarted(false);
  }, [location]);

  return (
    <div className="block mb-3">
      <div className="flex items-end justify-between">
        <div>
          {title && <label className={styles.title}>{title}</label>}
          {description && <span className={styles.description}>{description}</span>}
        </div>
        {isRequired && <ExclamationTriangleIcon className={`h-4 w-4 mb-1 ${error ? "text-red-600" : "text-slate-700"}`} />}
      </div>
      <div className={`input-editor${error ? " input-editor-error" : ""}${disabled ? " input-editor-disabled" : ""}`}>
        {uninitialized ? (
          <EditorProvider
            key={name}
            content={value}
            onBlur={onBlur}
            onUpdate={onUpdate}
            editable={!disabled}
            extensions={extensions}
            slotBefore={<MenuBar disabled={disabled} modules={{ modules, formats, forecolors, backcolors }} />}
          />
        ) : (
          <div className="h-80" />
        )}
      </div>
      {error && <p className="mt-1 text-xs text-red-600">{errorMessage}</p>}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import slugify from "slugify";
import Component from "../../atoms/inputs";
import FormParts from "../../atoms/form/parts";
import { manageHiddenFields } from "./functions";
import { objectsAreEqual } from "app/utils/validators/dataValidator";
import { getRandomString } from "app/utils/content";

export default function Form(props) {
  const { value, structure, onChange, errors, disabled, onKeyDown, tabMode = false, tab, mode, defaultValue, changed, className } = props;

  const [idForm, setIdForm] = useState(null);

  useEffect(() => {
    if (!idForm) setIdForm(getRandomString(10));
  }, []);

  const getValue = (key) => (value ? value[key] : null);
  const getError = (input) => (errors && errors[input.key] ? errors[input.key].error : input.error);
  const getErrorMessage = (input) => (errors && errors[input.key] ? errors[input.key].errorMessage : input.errorMessage);

  const changeValue = (key, val) => {
    const tempValue = value ? { ...value, [key]: val } : { [key]: val };
    onChange && onChange({ ...tempValue });
  };

  useEffect(() => {
    if (value && defaultValue && changed) {
      if (!objectsAreEqual(defaultValue, value)) changed(true);
      else changed(false);
    }
  }, [value, defaultValue, changed]);

  return (
    <div className={className}>
      {structure &&
        structure
          .filter((item) => !tabMode || (tabMode && item.tab === tab))
          .map((input, key) => {
            // HIDE FIELD IF CONDITIONAL ACTIVE
            const hiddenFields = manageHiddenFields(structure, value);
            if (hiddenFields.includes(input.key)) return null;

            if (input.component) {
              const Input = FormParts[input.component];
              return <Input key={String(key)} {...input} />;
            }

            const Input = Component[input.type];
            if (!Input) {
              return (
                <div className="block relative mb-3">
                  <label className="block text-sm font-normal text-slate-700">
                    Missing input: <span className="text-red-600">{input.type}</span>
                  </label>
                </div>
              );
            }

            const replaceString = (replacement, text) => {
              if (!replacement || !replacement.length) return text;
              let regex,
                replaceString = text;
              for (var i = 0; i < replacement.length; i++) {
                regex = new RegExp(replacement[i], "g");
                replaceString = replaceString ? replaceString.replace(regex, "") : "";
              }
              return replaceString;
            };

            const onBlur = () => {
              if (!input.onBlur) return null;
              if (getValue(input.onBlur.key)) return null;
              const trim = true;
              const replacement = typeof input.onBlur.replacement != "undefined" ? input.onBlur.replacement : "";
              const lower = typeof input.onBlur.lower != "undefined" ? input.onBlur.lower : true;
              let remove = typeof input.onBlur.remove != "undefined" ? input.onBlur.remove : /[*+~.()'"!:@]/g;
              const removeExtra = typeof input.onBlur.removeExtra != "undefined" && input.onBlur.removeExtra ? ["\\$", "€", "%", "&", "ª", "º", "\\|", "±", "§", "\\<", "\\>"] : false;

              let _val = getValue(input.key);
              if (removeExtra) {
                remove = /[$*+~^_.,ªº#()'"!?:;@\\/=]/g;
                _val = replaceString(removeExtra, _val);
              }

              if (_val) changeValue(input.onBlur.key, slugify(_val, { replacement, lower, remove, trim }));
            };

            return (
              <Input
                key={String(key)}
                {...input}
                mode={input?.mode || mode}
                onBlur={onBlur}
                name={input.key}
                onKeyDown={onKeyDown}
                error={getError(input)}
                idForm={`${idForm}-${key}`}
                value={getValue(input.key)}
                errorMessage={getErrorMessage(input)}
                disabled={disabled || input.disabled}
                onChange={(val) => changeValue(input.key, val)}
              />
            );
          })}
    </div>
  );
}

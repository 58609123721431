import Icon from "app/components/atoms/icons/icon";
import Dropdown from "app/components/molecules/dropdown";
import ModalConfirm from "app/components/molecules/modals/modalConfirm";
import Pagination from "app/components/molecules/pagination";
import { i18n } from "app/i18n";
import { setPage, useDeleteAccountMutation, useGetAccountsCountQuery, useGetAccountsQuery } from "app/stores/private";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

export default function Account() {
  // NAVIGATE
  const dispatch = useDispatch();

  // PARAMS
  const { project } = useParams();

  // STATE
  const [modalConfirm, setModalConfirm] = useState(null);

  // STORE
  const { take, skip, page } = useSelector((store) => store.private);

  // VARS
  const where = { deletedAt: null };

  // REQUEST
  const { data, refetch } = useGetAccountsQuery({ take, skip, where, orderBy: { firstName: "asc" } });
  let { data: total } = useGetAccountsCountQuery({ where });

  // MUTATION
  const [deleteAccount, { isLoading }] = useDeleteAccountMutation();

  // FUNCTION
  function onDeleteAccount(item) {
    setModalConfirm({
      isOpen: true,
      type: "DELETE",
      title: i18n("modal.remove_account.title"),
      text: i18n("modal.remove_account.description"),
      buttonText: i18n("button.delete"),
      onClose: () => setModalConfirm(null),
      onConfirm: () => {
        deleteAccount({ id: item.id }).then((res) => {
          if (res?.data) {
            refetch();
            total--;
          }
          setModalConfirm(null);
        });
      },
    });
  }

  return (
    <>
      <div className="max-w-7xl mx-auto pt-20 px-6">
        {/* BREADCRUMBS */}
        <div className="mb-3">
          <Link to={`/project/${project}/dashboard`} className="inline-flex items-center gap-2 text-sm text-gray-400 hover:text-primary-500 transition-all duration-200 ease-linear">
            <Icon name="chevronLeft" height="h-4" width="w-4" />
            <span>{i18n("label.back")}</span>
          </Link>
        </div>

        {/* HEADER */}
        <div className="flex justify-between mb-10">
          <div>
            <h1 className="text-2xl font-bold text-slate-900">Contas</h1>
            <p className="text-xs text-gray-400">{total} Contas</p>
          </div>
        </div>

        <div>
          <div className="overflow-x-auto overflow-y-visible relative h-full pb-24">
            <table className="listTable w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="relative text-xs text-gray-700 before:bg-gray-200 before:absolute before:inset-0 before:bottom-[10px] before:rounded-lg after:leading-[10px] after:-indent-96 after:block after:content-['']">
                <tr className="relative">
                  <th scope="col" className="p-6">
                    <span>{i18n("label.name")}</span>
                  </th>
                  <th scope="col" className="p-6">
                    <span>{i18n("label.email")}</span>
                  </th>
                  <th scope="col" className="p-6">
                    <span>{i18n("label.origin")}</span>
                  </th>
                  <th></th>
                </tr>
              </thead>
              {!!data?.length ? (
                <tbody className="relative">
                  {data.map((item, key) => (
                    <tr key={key}>
                      <td>
                        <span>{item.name}</span>
                      </td>
                      <td>
                        <span>{item.email}</span>
                      </td>
                      <td>
                        <span>{item.provider}</span>
                      </td>
                      <td>
                        <Dropdown icon="ellipsis" options={[{ title: i18n("button.delete"), onClick: () => onDeleteAccount(item), icon: "delete", delete: true }]} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody className="relative">
                  <tr>
                    <td colSpan={3} className="!text-center">
                      {i18n("label.nothing_found")}
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>

          <div className="flex justify-center mb-20">
            <Pagination total={total} perPage={take} current={page} onChange={(p) => dispatch(setPage(p))} />
          </div>
        </div>
      </div>
      <ModalConfirm {...modalConfirm} isLoading={isLoading} />
    </>
  );
}

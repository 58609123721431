import ModalConfirm from "app/components/molecules/modals/modalConfirm";
import ProjectNavbar from "app/components/molecules/navbars/project";
import ProjectHeader from "app/components/molecules/navbars/projectHeader";
import { i18n as translate } from "app/i18n";
import { setProject, useGetProjectInfoQuery } from "app/stores/project";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useParams } from "react-router-dom";
import ReactRouterPrompt from "react-router-prompt";
import { ToastContainer } from "react-toastify";
import Blocks from "../Blocks";
import BlocksEdit from "../BlocksEdit";
import Forms from "../Forms";
import FormsEdit from "../FormsEdit";
import Multimedia from "../Multimedia";
import PostEdit from "../PostEdit";
import Posts from "../Posts";
import ProjectSettings from "../ProjectSettings";
import Resume from "../Resume";
import Translations from "../Translations";
import PrivateArea from "../PrivateArea";
import Account from "../PrivateArea/Accounts";
import MapRoutes from "../PrivateArea/Route";
import RouteEdit from "../PrivateArea/Route/Edit";
import ExperienceEdit from "../PrivateArea/Experience/Edit";
import Experience from "../PrivateArea/Experience";
import PartnerEdit from "../PrivateArea/Partner/Edit";
import Partner from "../PrivateArea/Partner";
import StoryEdit from "../PrivateArea/Story/Edit";
import Story from "../PrivateArea/Story";
import Category from "../PrivateArea/Category";
import PrivareAreaMiddleware from "app/components/middleware/privateArea";
import EventEdit from "../PrivateArea/Event/Edit";
import Event from "../PrivateArea/Event";
import Reserved from "../Reserved";

export default function Project() {
  let { project: id } = useParams();
  const { i18n } = useTranslation();

  // LOAD BACKGROUND INFORMATION
  const { isUninitialized, refetch } = useGetProjectInfoQuery({ id }, { skip: false });

  const dispatch = useDispatch();
  const { info, language, unsavedChanges, sidebarChanges, headerChanges } = useSelector((state) => state.project);

  useEffect(() => {
    if (id && refetch) refetch();
  }, [id, refetch]);

  useEffect(() => {
    return () => {
      if (isUninitialized) dispatch(setProject(null));
    };
  }, [isUninitialized, dispatch]);

  const onChangeLanguage = (locale) => {
    i18n.changeLanguage(locale);
  };

  const prepareInfo = (data) => {
    if (!data) return;
    let temp = { ...data };

    // POST TYPES
    if (temp?.postTypes?.length) {
      temp.postTypes = temp.postTypes.map((pt) => {
        return {
          ...pt,
          posts: pt?.posts?.filter((e) => e.content?.language?.slug === locale),
          count: pt?.posts?.filter((e) => e.content?.language?.slug === locale).length,
        };
      });
    }

    return temp;
  };

  const locale = language?.slug;
  const runPrompt = unsavedChanges || sidebarChanges || headerChanges;

  return (
    <>
      <div className="flex h-full">
        <ProjectNavbar {...prepareInfo(info)} />
        <div className="flex flex-1 flex-col overflow-hidden">
          <ProjectHeader toggleLanguage={onChangeLanguage} />
          <div className="flex flex-1 items-stretch overflow-hidden">
            <main className="flex-1 relative overflow-y-auto">
              <Routes>
                {/* POSTS */}
                <Route path="posts/*">
                  <Route path=":key/:post/:lang" element={<PostEdit />} />
                  <Route exact path=":key" element={<Posts />} />
                </Route>
                {/* BLOCKS */}
                <Route path="blocks/*">
                  <Route path=":block/:lang" element={<BlocksEdit />} />
                  <Route index element={<Blocks />} />
                </Route>
                {/* FORMS */}
                <Route path="forms/*">
                  <Route path=":id/:lang" element={<FormsEdit />} />
                  <Route index element={<Forms />} />
                </Route>
                {/* MULTIMEDIA */}
                <Route path="media" element={<Multimedia />} />
                {/* TRANSLATIONS */}
                <Route path="translations" element={<Translations />} />
                {/* RESERVED AREA */}
                <Route path="reserved" element={<Reserved />} />
                {/* PROJECT SETTINGS */}
                <Route path="settings/*" element={<ProjectSettings />}>
                  <Route path=":key" element={<ProjectSettings />} />
                </Route>
                {/* DASHBOARD */}
                <Route path="dashboard/*" element={<PrivareAreaMiddleware />}>
                  <Route path="accounts" element={<Account />} />
                  <Route path="routes/*">
                    <Route path=":id" element={<RouteEdit />} />
                    <Route index element={<MapRoutes />} />
                  </Route>
                  <Route path="experiences/*">
                    <Route path=":id/:lang" element={<ExperienceEdit />} />
                    <Route index element={<Experience />} />
                  </Route>
                  <Route path="partners/*">
                    <Route path=":id/:lang" element={<PartnerEdit />} />
                    <Route index element={<Partner />} />
                  </Route>
                  <Route path="events/*">
                    <Route path=":id/:lang" element={<EventEdit />} />
                    <Route index element={<Event />} />
                  </Route>
                  <Route path="stories/*">
                    <Route path=":id/:lang" element={<StoryEdit />} />
                    <Route index element={<Story />} />
                  </Route>
                  <Route path="categories" element={<Category />} />
                  <Route index element={<PrivateArea />} />
                </Route>
                <Route index element={<Resume />} />
              </Routes>
            </main>
          </div>
        </div>

        {/* TOAST FOR ALERTS */}
        <ToastContainer position="top-right" />

        {/* PROMPT TO PREVENT CHANGES */}
        <ReactRouterPrompt when={runPrompt}>
          {({ isActive, onConfirm, onCancel }) => (
            <ModalConfirm
              isOpen={isActive}
              type={"SAVE"}
              mode={"BASIC"}
              title={translate("label.ignore_changes.title")}
              text={translate("label.ignore_changes.text")}
              buttonText={translate("label.ignore_changes.button")}
              onClose={onCancel}
              onConfirm={onConfirm}
            />
          )}
        </ReactRouterPrompt>
      </div>
    </>
  );
}

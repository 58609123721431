import { gql } from "@apollo/client";
import { createSlice } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { serverAuth } from "app/utils/server";

const initialState = {
  accounts: [],
  stories: [],
  partners: [],
  categories: [],

  take: 12,
  page: 0,
  skip: 0,
  where: {},
  orderBy: { createdAt: "desc" },
};

export const privateSlice = createSlice({
  name: "private",
  initialState,
  reducers: {
    setSkip: (state, { payload }) => {
      state.skip = payload;
    },
    setWhere: (state, { payload }) => {
      state.where = payload;
    },
    setOrderBy: (state, { payload }) => {
      state.orderBy = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
      state.skip = state.page * state.take;
    },
    setPartners: (state, { payload }) => {
      state.partners = payload;
    },
    setCategories: (state, { payload }) => {
      state.categories = payload;
    },
  },
});

export const privateApi = createApi({
  reducerPath: "privateAPI",
  baseQuery: graphqlRequestBaseQuery(serverAuth),
  endpoints: (builder) => ({
    getInformation: builder.query({
      query: () => ({
        document: gql`
          query {
            categories: mapCategories {
              id
              types
              translations {
                value
                language {
                  slug
                }
              }
            }
            partners: elements(where: { type: PARTNER }) {
              id
              type
              translations {
                title
                language {
                  slug
                }
              }
            }
          }
        `,
      }),
      transformResponse: (response) => response,
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(privateSlice.actions.setCategories(data.categories));
          dispatch(privateSlice.actions.setPartners(data.partners));
        } catch (err) {
          console.error("err", err);
        }
      },
    }),
    // ACCOUNT
    getAccounts: builder.query({
      query: ({ take, skip, where, orderBy }) => ({
        document: gql`
          query ($take: Int, $skip: Int, $where: AccountWhere, $orderBy: AccountOrderBy) {
            accounts(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
              id
              name
              email
              provider
            }
          }
        `,
        variables: {
          take,
          skip,
          where,
          orderBy,
        },
      }),
      transformResponse: (response) => response.accounts,
    }),
    getAccountsCount: builder.query({
      query: ({ where }) => ({
        document: gql`
          query ($where: AccountWhere) {
            accountsCount(where: $where)
          }
        `,
        variables: {
          where,
        },
      }),
      transformResponse: (response) => response.accountsCount,
    }),
    deleteAccount: builder.mutation({
      query: ({ id }) => ({
        document: gql`
          mutation deleteAccount($id: ID!) {
            deleteAccount(id: $id) {
              id
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (response) => response.deleteAccount,
    }),
    // STORY
    getStories: builder.query({
      query: ({ take, skip, where, orderBy }) => ({
        document: gql`
          query ($take: Int, $skip: Int, $where: MapElementWhere, $orderBy: MapElementOrderBy) {
            elements(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
              id
              type
              translations {
                title
                slug
                published
                language {
                  slug
                }
              }
            }
          }
        `,
        variables: {
          take,
          skip,
          where: { ...where, type: "STORY" },
          orderBy,
        },
      }),
      transformResponse: (response) => response.elements,
    }),
    getStory: builder.query({
      query: ({ id }) => ({
        document: gql`
          query ($id: ID!) {
            element(id: $id) {
              id
              type
              duration
              latitude
              longitude
              place {
                name
                city {
                  name
                  district {
                    name
                  }
                }
              }
              image {
                id
                path
              }
              video {
                id
                path
              }
              categories {
                id
                translations {
                  value
                  language {
                    slug
                  }
                }
              }
              translations {
                id
                slug
                path
                title
                metatags
                published
                language {
                  slug
                }
                revisions {
                  id
                  data
                }
              }
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (response) => response.element,
    }),
    createStory: builder.mutation({
      query: ({ title, slug, type, language }) => ({
        document: gql`
          mutation ($title: String!, $slug: String!, $type: ElementType!, $language: String!) {
            createElement(title: $title, slug: $slug, type: $type, language: $language) {
              id
              type
              translations {
                title
                slug
                language {
                  slug
                }
              }
            }
          }
        `,
        variables: {
          title,
          slug,
          type,
          language,
        },
      }),
      transformResponse: (response) => response.createElement,
    }),
    updateStory: builder.mutation({
      query: ({ id, language, data }) => ({
        document: gql`
          mutation ($id: ID!, $language: String!, $data: ElementInput!) {
            updateElement(id: $id, language: $language, data: $data) {
              id
              type
              duration
              latitude
              longitude
              place {
                name
                city {
                  name
                  district {
                    name
                  }
                }
              }
              image {
                id
                path
              }
              video {
                id
                path
              }
              categories {
                id
                translations {
                  value
                  language {
                    slug
                  }
                }
              }
              translations {
                id
                slug
                path
                title
                metatags
                published
                language {
                  slug
                }
                revisions {
                  id
                  data
                }
              }
            }
          }
        `,
        variables: {
          id,
          data,
          language,
        },
      }),
      transformResponse: (response) => response.updateElement,
    }),
    deleteStory: builder.mutation({
      query: ({ id }) => ({
        document: gql`
          mutation ($id: ID!) {
            deleteElement(id: $id) {
              id
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (response) => response.deleteElement,
    }),
    getStoriesCount: builder.query({
      query: ({ where }) => ({
        document: gql`
          query ($where: MapElementWhere) {
            elementsCount(where: $where)
          }
        `,
        variables: {
          where: { ...where, type: "STORY" },
        },
      }),
      transformResponse: (response) => response.elementsCount,
    }),
    // EXPERIENCE
    getExperiences: builder.query({
      query: ({ take, skip, where, orderBy }) => ({
        document: gql`
          query ($take: Int, $skip: Int, $where: MapElementWhere, $orderBy: MapElementOrderBy) {
            elements(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
              id
              type
              translations {
                title
                slug
                published
                language {
                  slug
                }
              }
            }
          }
        `,
        variables: {
          take,
          skip,
          where: { ...where, type: "EXPERIENCE" },
          orderBy,
        },
      }),
      transformResponse: (response) => response.elements,
    }),
    getExperience: builder.query({
      query: ({ id }) => ({
        document: gql`
          query ($id: ID!) {
            element(id: $id) {
              id
              type
              duration
              latitude
              longitude
              place {
                name
                city {
                  name
                  district {
                    name
                  }
                }
              }
              image {
                id
                path
              }
              video {
                id
                path
              }
              categories {
                id
                translations {
                  value
                  language {
                    slug
                  }
                }
              }
              translations {
                id
                slug
                path
                title
                metatags
                published
                language {
                  slug
                }
                revisions {
                  id
                  data
                }
              }
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (response) => response.element,
    }),
    createExperience: builder.mutation({
      query: ({ title, slug, type, language }) => ({
        document: gql`
          mutation ($title: String!, $slug: String!, $type: ElementType!, $language: String!) {
            createElement(title: $title, slug: $slug, type: $type, language: $language) {
              id
              type
              translations {
                title
                slug
                language {
                  slug
                }
              }
            }
          }
        `,
        variables: {
          title,
          slug,
          type,
          language,
        },
      }),
      transformResponse: (response) => response.createElement,
    }),
    updateExperience: builder.mutation({
      query: ({ id, language, data }) => ({
        document: gql`
          mutation ($id: ID!, $language: String!, $data: ElementInput!) {
            updateElement(id: $id, language: $language, data: $data) {
              id
              type
              duration
              latitude
              longitude
              place {
                name
                city {
                  name
                  district {
                    name
                  }
                }
              }
              image {
                id
                path
              }
              video {
                id
                path
              }
              categories {
                id
                translations {
                  value
                  language {
                    slug
                  }
                }
              }
              translations {
                id
                slug
                path
                title
                metatags
                published
                language {
                  slug
                }
                revisions {
                  id
                  data
                }
              }
            }
          }
        `,
        variables: {
          id,
          data,
          language,
        },
      }),
      transformResponse: (response) => response.updateElement,
    }),
    deleteExperience: builder.mutation({
      query: ({ id }) => ({
        document: gql`
          mutation ($id: ID!) {
            deleteElement(id: $id) {
              id
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (response) => response.deleteElement,
    }),
    getExperiencesCount: builder.query({
      query: ({ where }) => ({
        document: gql`
          query ($where: MapElementWhere) {
            elementsCount(where: $where)
          }
        `,
        variables: {
          where: { ...where, type: "EXPERIENCE" },
        },
      }),
      transformResponse: (response) => response.elementsCount,
    }),
    // CATEGORY
    getCategories: builder.query({
      query: ({ take, skip, where, orderBy }) => ({
        document: gql`
          query ($take: Int, $skip: Int, $where: MapCategoryWhere, $orderBy: MapCategoryOrderBy) {
            mapCategories(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
              id
              icon
              types
              translations {
                value
                language {
                  slug
                }
              }
            }
          }
        `,
        variables: {
          take,
          skip,
          where,
          orderBy,
        },
      }),
      transformResponse: (response) => response.mapCategories,
    }),
    getCategoriesCount: builder.query({
      query: ({ where }) => ({
        document: gql`
          query ($where: MapCategoryWhere) {
            mapCategoriesCount(where: $where)
          }
        `,
        variables: {
          where,
        },
      }),
      transformResponse: (response) => response.mapCategoriesCount,
    }),
    createCategory: builder.mutation({
      query: ({ title, icon, types, language }) => ({
        document: gql`
          mutation ($title: String!, $icon: String!, $types: [ElementType!]!, $language: String!) {
            createMapCategory(title: $title, icon: $icon, types: $types, language: $language) {
              id
              icon
              types
              translations {
                value
                language {
                  slug
                }
              }
            }
          }
        `,
        variables: {
          title,
          icon,
          language,
          types: types?.map((e) => e.value) || undefined,
        },
      }),
      transformResponse: (response) => response.createMapCategory,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const {
            private: { categories },
          } = getState();

          // UPDATE CATEGORIES LIST
          const temp = [...categories, data];
          dispatch(privateSlice.actions.setCategories([...temp]));
        } catch (err) {
          console.error("err", err);
        }
      },
    }),
    updateCategory: builder.mutation({
      query: ({ id, icon, types, translations }) => ({
        document: gql`
          mutation ($id: ID!, $icon: String!, $types: [ElementType!]!, $translations: [InputValueTranslation!]!) {
            updateMapCategory(id: $id, icon: $icon, types: $types, translations: $translations) {
              id
              icon
              types
              translations {
                value
                language {
                  slug
                }
              }
            }
          }
        `,
        variables: {
          id,
          icon,
          types: types?.map((e) => e.value) || undefined,
          translations: Object.keys(translations).map((key) => ({ language: key, value: translations[key] })),
        },
      }),
      transformResponse: (response) => response.updateMapCategory,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const {
            private: { categories },
          } = getState();

          // UPDATE CATEGORIES LIST
          const temp = [...categories];
          const index = temp.findIndex((c) => c.id === data.id);
          if (index !== -1) temp[index] = { ...data };
          else temp.push(data);
          dispatch(privateSlice.actions.setCategories([...temp]));
        } catch (err) {
          console.error("err", err);
        }
      },
    }),
    deleteCategory: builder.mutation({
      query: ({ id }) => ({
        document: gql`
          mutation ($id: ID!) {
            deleteMapCategory(id: $id) {
              id
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (response) => response.deleteMapCategory,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const {
            private: { categories },
          } = getState();

          // UPDATE CATEGORIES LIST
          const temp = [...categories];
          const index = temp.findIndex((c) => c.id === data.id);
          if (index !== -1) temp.splice(index, 1);
          dispatch(privateSlice.actions.setCategories([...temp]));
        } catch (err) {
          console.error("err", err);
        }
      },
    }),
    // PARTNER
    getPartners: builder.query({
      query: ({ take, skip, where, orderBy }) => ({
        document: gql`
          query ($take: Int, $skip: Int, $where: MapElementWhere, $orderBy: MapElementOrderBy) {
            elements(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
              id
              type
              score
              translations {
                title
                slug
                published
                language {
                  slug
                }
              }
            }
          }
        `,
        variables: {
          take,
          skip,
          where: { ...where, type: "PARTNER" },
          orderBy,
        },
      }),
      transformResponse: (response) => response.elements,
    }),
    getPartner: builder.query({
      query: ({ id }) => ({
        document: gql`
          query ($id: ID!) {
            element(id: $id) {
              id
              type
              email
              score
              address
              banners
              channels
              duration
              latitude
              longitude
              phonenumber
              place {
                name
                city {
                  name
                  district {
                    name
                  }
                }
              }
              image {
                id
                path
              }
              categories {
                id
                translations {
                  value
                  language {
                    slug
                  }
                }
              }
              translations {
                id
                slug
                path
                title
                metatags
                published
                language {
                  slug
                }
                revisions {
                  id
                  data
                }
              }
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (response) => response.element,
    }),
    createPartner: builder.mutation({
      query: ({ title, slug, type, language }) => ({
        document: gql`
          mutation ($title: String!, $slug: String!, $type: ElementType!, $language: String!) {
            createElement(title: $title, slug: $slug, type: $type, language: $language) {
              id
              type
              translations {
                title
                slug
                language {
                  slug
                }
              }
            }
          }
        `,
        variables: {
          title,
          slug,
          type,
          language,
        },
      }),
      transformResponse: (response) => response.createElement,
    }),
    updatePartner: builder.mutation({
      query: ({ id, language, data }) => ({
        document: gql`
          mutation ($id: ID!, $language: String!, $data: ElementInput!) {
            updateElement(id: $id, language: $language, data: $data) {
              id
              type
              email
              score
              banners
              channels
              duration
              latitude
              longitude
              address
              phonenumber
              place {
                name
                city {
                  name
                  district {
                    name
                  }
                }
              }
              image {
                id
                path
              }
              translations {
                id
                slug
                path
                title
                metatags
                published
                language {
                  slug
                }
                revisions {
                  id
                  data
                }
              }
            }
          }
        `,
        variables: {
          id,
          data,
          language,
        },
      }),
      transformResponse: (response) => response.updateElement,
    }),
    deletePartner: builder.mutation({
      query: ({ id }) => ({
        document: gql`
          mutation ($id: ID!) {
            deleteElement(id: $id) {
              id
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (response) => response.deleteElement,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const {
            private: { partners },
          } = getState();

          // DELETE PARTNER FROM LIST
          const temp = [...partners];
          const index = temp.findIndex((c) => c.id === data.id);
          if (index !== -1) temp.splice(index, 1);
          dispatch(privateSlice.actions.setPartners([...temp]));
        } catch (err) {
          console.error("err", err);
        }
      },
    }),
    getPartnersCount: builder.query({
      query: ({ where }) => ({
        document: gql`
          query ($where: MapElementWhere) {
            elementsCount(where: $where)
          }
        `,
        variables: {
          where: { ...where, type: "PARTNER" },
        },
      }),
      transformResponse: (response) => response.elementsCount,
    }),
    uploadPartners: builder.mutation({
      query: ({ items, language }) => ({
        document: gql`
          mutation ($language: String!, $items: [PartnerInput!]!) {
            uploadPartners(language: $language, items: $items)
          }
        `,
        variables: {
          items,
          language,
        },
      }),
      transformResponse: (response) => response.uploadPartners,
    }),
    // EVENTS
    getEvents: builder.query({
      query: ({ take, skip, where, orderBy }) => ({
        document: gql`
          query ($take: Int, $skip: Int, $where: MapElementWhere, $orderBy: MapElementOrderBy) {
            elements(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
              id
              type
              translations {
                title
                slug
                published
                language {
                  slug
                }
              }
            }
          }
        `,
        variables: {
          take,
          skip,
          where: { ...where, type: "EVENT" },
          orderBy,
        },
      }),
      transformResponse: (response) => response.elements,
    }),
    getEvent: builder.query({
      query: ({ id }) => ({
        document: gql`
          query ($id: ID!) {
            element(id: $id) {
              id
              type
              endDate
              address
              dateType
              latitude
              startDate
              longitude
              place {
                name
                city {
                  name
                  district {
                    name
                  }
                }
              }
              image {
                id
                path
              }
              video {
                id
                path
              }
              categories {
                id
                translations {
                  value
                  language {
                    slug
                  }
                }
              }
              translations {
                id
                slug
                path
                title
                metatags
                published
                language {
                  slug
                }
                revisions {
                  id
                  data
                }
              }
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (response) => response.element,
    }),
    getEventsCount: builder.query({
      query: ({ where }) => ({
        document: gql`
          query ($where: MapElementWhere) {
            elementsCount(where: $where)
          }
        `,
        variables: {
          where: { ...where, type: "EVENT" },
        },
      }),
      transformResponse: (response) => response.elementsCount,
    }),
    createEvent: builder.mutation({
      query: ({ title, slug, type, language }) => ({
        document: gql`
          mutation ($title: String!, $slug: String!, $type: ElementType!, $language: String!) {
            createElement(title: $title, slug: $slug, type: $type, language: $language) {
              id
              type
              translations {
                title
                slug
                language {
                  slug
                }
              }
            }
          }
        `,
        variables: {
          title,
          slug,
          type,
          language,
        },
      }),
      transformResponse: (response) => response.createElement,
    }),
    updateEvent: builder.mutation({
      query: ({ id, language, data }) => ({
        document: gql`
          mutation ($id: ID!, $language: String!, $data: ElementInput!) {
            updateElement(id: $id, language: $language, data: $data) {
              id
              type
              endDate
              address
              dateType
              latitude
              startDate
              longitude
              place {
                name
                city {
                  name
                  district {
                    name
                  }
                }
              }
              image {
                id
                path
              }
              video {
                id
                path
              }
              categories {
                id
                translations {
                  value
                  language {
                    slug
                  }
                }
              }
              translations {
                id
                slug
                path
                title
                metatags
                published
                language {
                  slug
                }
                revisions {
                  id
                  data
                }
              }
            }
          }
        `,
        variables: {
          id,
          data,
          language,
        },
      }),
      transformResponse: (response) => response.updateElement,
    }),
    deleteEvent: builder.mutation({
      query: ({ id }) => ({
        document: gql`
          mutation ($id: ID!) {
            deleteElement(id: $id) {
              id
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (response) => response.deleteElement,
    }),
    // ROUTE
    getRoutes: builder.query({
      query: ({ take, skip, where, orderBy }) => ({
        document: gql`
          query routes($take: Int, $skip: Int, $where: RouteWhere, $orderBy: RouteOrderBy) {
            routes(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
              id
              title
              admin
              status
              gallery
              authorName
              showAuthor
              description
              author {
                id
                name
              }
              elements {
                index
                element {
                  id
                  type
                  duration
                  image {
                    href
                  }
                  place {
                    name
                    city {
                      name
                      district {
                        name
                      }
                    }
                  }
                  translations {
                    id
                    url
                    title
                    language {
                      slug
                    }
                  }
                }
              }
            }
          }
        `,
        variables: {
          take,
          skip,
          where,
          orderBy,
        },
      }),
      transformResponse: (response) => response.routes,
    }),
    getRoutesCount: builder.query({
      query: ({ where }) => ({
        document: gql`
          query ($where: RouteWhere) {
            routesCount(where: $where)
          }
        `,
        variables: {
          where: { ...where },
        },
      }),
      transformResponse: (response) => response.routesCount,
    }),
    createRoute: builder.mutation({
      query: ({ title, description, gallery, status, authorName, showAuthor, elements }) => ({
        document: gql`
          mutation ($data: RouteInput!) {
            createRoute(data: $data) {
              id
            }
          }
        `,
        variables: {
          data: {
            title,
            description,
            authorName,
            showAuthor: !!showAuthor,
            gallery: gallery || [],
            status: status ? "PUBLIC" : "DRAFT",
            elements: elements.filter((e) => !!e?.element?.element?.value).map((e) => e.element.element.value),
          },
        },
      }),
      transformResponse: (response) => response.createRoute,
    }),
    updateRoute: builder.mutation({
      query: ({ id, title, description, gallery, status, showAuthor, authorName, elements }) => ({
        document: gql`
          mutation ($id: ID!, $data: RouteInput!) {
            updateRoute(id: $id, data: $data) {
              id
            }
          }
        `,
        variables: {
          id,
          data: {
            title,
            authorName,
            description,
            showAuthor: showAuthor || false,
            gallery: gallery || [],
            status: status ? "PUBLIC" : "DRAFT",
            elements: elements.filter((e) => !!e?.element?.element?.value).map((e) => e.element.element.value),
          },
        },
      }),
      transformResponse: (response) => response.updateRoute,
    }),
    becomeSuggestion: builder.mutation({
      query: ({ id }) => ({
        document: gql`
          mutation routeToSuggestion($id: ID!) {
            routeToSuggestion(id: $id) {
              id
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (response) => response.routeToSuggestion,
    }),
    deleteRoute: builder.mutation({
      query: ({ id }) => ({
        document: gql`
          mutation ($id: ID!) {
            deleteRoute(id: $id) {
              id
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (response) => response.deleteRoute,
    }),

    // GLOBAL
    translateElement: builder.mutation({
      query: ({ element, language, title, slug, content, auto }) => ({
        document: gql`
          mutation ($element: ID!, $language: String!, $title: String!, $slug: String!, $content: ID, $auto: Boolean!, $type: ElementType!) {
            translateElement(element: $element, language: $language, title: $title, slug: $slug, content: $content, auto: $auto, type: $type) {
              id
              translations {
                path
                language {
                  slug
                }
              }
            }
          }
        `,
        variables: {
          element,
          language,
          title,
          slug,
          auto: !!auto,
          type: "STORY",
          content: content?.value || undefined,
        },
      }),
      transformResponse: (response) => response.translateElement,
    }),
  }),
});

export const { setSkip, setWhere, setOrderBy, setPage, setCategories, setPartners } = privateSlice.actions;

export const {
  useGetInformationQuery,

  // ACCOUNT
  useGetAccountsQuery,
  useGetAccountsCountQuery,
  useDeleteAccountMutation,

  useGetStoryQuery,
  useGetStoriesQuery,
  useGetStoriesCountQuery,
  useCreateStoryMutation,
  useUpdateStoryMutation,
  useDeleteStoryMutation,

  useGetExperienceQuery,
  useGetExperiencesQuery,
  useGetExperiencesCountQuery,
  useCreateExperienceMutation,
  useUpdateExperienceMutation,
  useDeleteExperienceMutation,

  useGetEventQuery,
  useGetEventsQuery,
  useGetEventsCountQuery,
  useCreateEventMutation,
  useUpdateEventMutation,
  useDeleteEventMutation,

  useGetCategoriesQuery,
  useGetCategoriesCountQuery,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,

  useGetPartnerQuery,
  useGetPartnersQuery,
  useGetPartnersCountQuery,
  useCreatePartnerMutation,
  useUpdatePartnerMutation,
  useDeletePartnerMutation,
  useUploadPartnersMutation,

  useGetRoutesQuery,
  useGetRoutesCountQuery,
  useCreateRouteMutation,
  useUpdateRouteMutation,
  useBecomeSuggestionMutation,
  useDeleteRouteMutation,

  useTranslateElementMutation,
} = privateApi;

export default privateSlice.reducer;

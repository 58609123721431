import { i18n } from "app/i18n";
import validator from "validator";

const keyOptions = { replacement: "_", trim: true, remove: /[*+~.()'"!:@]/g };

const components = [
  {
    type: "Group",
    key: "translations",
    title: i18n("label.translations"),
    format: "table",
    children: [],
  },
];

const input = {
  type: "Text",
  title: "Title",
  key: "title",
  rules: [
    {
      method: validator.isEmpty,
      validWhen: false,
      message: i18n("input.required_field"),
    },
  ],
};

const inputIcon = {
  type: "SelectIcon",
  title: "Icon",
  key: "icon",
  rules: [
    {
      method: validator.isEmpty,
      validWhen: false,
      message: i18n("input.required_field"),
    },
  ],
};
const inputImage = {
  type: "Multimedia",
  key: "thumbnail",
  clearMode: true,
  noAlt: true,
  title: i18n("label.thumbnail"),
};

const inputKey = {
  type: "Text",
  key: "key",
  title: i18n("label.key"),
  rules: [
    {
      method: validator.isEmpty,
      validWhen: false,
      message: i18n("input.required_field"),
    },
  ],
};

export { components, input, inputKey, inputIcon, inputImage, keyOptions };
